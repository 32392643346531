import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_blog.js";
import { Image, Gallery, Thumb } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p><strong parentName="p">{`The bike season in 2017 was very long and we traveled crisscross Europe,
explored popular and less known places from Norway to Italy, from Slovenia to
Spain. See the best pictures of the whole year and find the trails they were
shot on. Click on the pictures to enlarge them and find the link to the
trails in the description.`}</strong></p>
    <br />
    <p>{`Win one of five Trailguide Calendars 2018 with the 12 best pictures printed
in high quality on premium paper. Scroll down to find out more`}</p>
    <br />
    <Gallery mdxType="Gallery">
  <Thumb src="news/bestof2017/bestof_18.jpg" mdxType="Thumb">
    Piz Nair Screeline, St. Moritz, Switzerland
  </Thumb>
  <Thumb src="news/bestof2017/bestof_19.jpg" mdxType="Thumb">
    Suvretta Trail, St. Moritz, Swizerland
  </Thumb>
  <Thumb src="news/bestof2017/bestof_20.jpg" mdxType="Thumb">
    Corviglia Flow Trail, St. Moritz, Swizerland
  </Thumb>
  <Thumb src="news/bestof2017/bestof_24.jpg" mdxType="Thumb">
    Alps Epic Trail, Davos, Switzerland
  </Thumb>
  <Thumb src="news/bestof2017/bestof_25.jpg" mdxType="Thumb">
    Jakobshorn, Davos, Switzerland
  </Thumb>
  <Thumb src="news/bestof2017/bestof_26.jpg" mdxType="Thumb">
    Jakobshorn, Davos, Switzerland
  </Thumb>
  <Thumb src="news/bestof2017/bestof_21.jpg" mdxType="Thumb">
    Corviglia Flow Trail, St. Moritz, Swizerland
  </Thumb>
  <Thumb src="news/bestof2017/bestof_22.jpg" mdxType="Thumb">
    Corviglia Flow Trail, St. Moritz, Swizerland
  </Thumb>
  <Thumb src="news/bestof2017/bestof_23.jpg" mdxType="Thumb">Piz Nair technical</Thumb>
  <Thumb src="news/bestof2017/bestof_27.jpg" mdxType="Thumb">
    Jakobshorn, Davos, Switzerland
  </Thumb>
  <Thumb src="news/bestof2017/bestof_28.jpg" mdxType="Thumb">
    Alp Sanaspans, Lenzerheide, Switzerland
  </Thumb>
  <Thumb src="news/bestof2017/bestof_29.jpg" mdxType="Thumb">
    Sanaspans, Lenzerheide, Switzerland
  </Thumb>
  <Thumb src="news/bestof2017/bestof_6.jpg" mdxType="Thumb">
    Kamniski vrh, Ljubljana, Slovenia
  </Thumb>
  <Thumb src="news/bestof2017/bestof_8.jpg" mdxType="Thumb">
    Bovec Enduro Loop, Soca Valley, Slovenia
  </Thumb>
  <Thumb src="news/bestof2017/bestof_9.jpg" mdxType="Thumb">
    Bovec Enduro Loop, Socca Valley, Slovenia
  </Thumb>
  <Thumb src="news/bestof2017/bestof_30.jpg" mdxType="Thumb">
    Pierre Avoi, Verbier, Switzerland
  </Thumb>
  <Thumb src="news/bestof2017/bestof_31.jpg" mdxType="Thumb">
    Gornergrat Japanese Highway, Zermatt, Switzerland
  </Thumb>
  <Thumb src="news/bestof2017/bestof_32.jpg" mdxType="Thumb">
    Militärweg, Zermatt, Switzerland
  </Thumb>
  <Thumb src="news/bestof2017/bestof_33.jpg" mdxType="Thumb">
    Matterhorn, Zermatt, Switzerland
  </Thumb>
  <Thumb src="news/bestof2017/bestof_34.jpg" mdxType="Thumb">
    Gornergratsee, Zermatt, Switzerland
  </Thumb>
  <Thumb src="news/bestof2017/bestof_11.jpg" mdxType="Thumb">Fischleintal, Sexten, Italy</Thumb>
  <Thumb src="news/bestof2017/bestof_35.jpg" mdxType="Thumb">
    Bettmergrat, Brig, Switzerland
  </Thumb>
  <Thumb src="news/bestof2017/bestof_36.jpg" mdxType="Thumb">
    Bettmersee, Bettmeralp, Switzerland
  </Thumb>
  <Thumb src="news/bestof2017/bestof_13.jpg" mdxType="Thumb">Pralongia, Arabba, Italy</Thumb>
  <Thumb src="news/bestof2017/bestof_38.jpg" mdxType="Thumb">Saint Nicolas, Aosta, Italy</Thumb>
  <Thumb src="news/bestof2017/bestof_39.jpg" mdxType="Thumb">Ru de Vens, Aosta, Italy</Thumb>
  <Thumb src="news/bestof2017/bestof_40.jpg" mdxType="Thumb">Becca Trail, Aosta, Italy</Thumb>
  <Thumb src="news/bestof2017/bestof_37.jpg" mdxType="Thumb">
    Visperterminen, Visp, Switzerland
  </Thumb>
  <Thumb src="news/bestof2017/bestof_41.jpg" mdxType="Thumb">
    Tossal de la Creu del Codó, Sant Llorenc de Morunys, Spain
  </Thumb>
  <Thumb src="news/bestof2017/bestof_42.jpg" mdxType="Thumb">
    Tossal de la Creu del Codó, Sant Llorenc de Morunys, Spain
  </Thumb>
  <Thumb src="news/bestof2017/bestof_43.jpg" mdxType="Thumb">
    Col d'Arenys Scree 2, Canillo, Andorra
  </Thumb>
  <Thumb src="news/bestof2017/bestof_44.jpg" mdxType="Thumb">
    Col d'Arenys Scree 1, Canillo, Andorra
  </Thumb>
  <Thumb src="news/bestof2017/bestof_45.jpg" mdxType="Thumb">Vielha, Val d'Aran, Spain</Thumb>
  <Thumb src="news/bestof2017/bestof_46.jpg" mdxType="Thumb">Magic Line, Benasque, Spain</Thumb>
  <Thumb src="news/bestof2017/bestof_47.jpg" mdxType="Thumb">Gallinero, Benasque, Spain</Thumb>
  <Thumb src="news/bestof2017/bestof_48.jpg" mdxType="Thumb">
    Rabaltueras, Benasque, Spain
  </Thumb>
  <Thumb src="news/bestof2017/bestof_49.jpg" mdxType="Thumb">
    Rabaltueras, Benasque, Spain
  </Thumb>
  <Thumb src="news/bestof2017/bestof_50.jpg" mdxType="Thumb">Coda Sartèn, Aínsa, Spain</Thumb>
  <Thumb src="news/bestof2017/bestof_51.jpg" mdxType="Thumb">
    Alguacil, Güejar Sierra, Spain
  </Thumb>
  <Thumb src="news/bestof2017/bestof_52.jpg" mdxType="Thumb">Majojos, Monachil, Spain</Thumb>
  <Thumb src="news/bestof2017/bestof_53.jpg" mdxType="Thumb">Majojos, Monachil, Spain</Thumb>
  <Thumb src="news/bestof2017/bestof_54.jpg" mdxType="Thumb">
    La Boca de la Pesca, La Zubia, Spain
  </Thumb>
  <Thumb src="news/bestof2017/bestof_55.jpg" mdxType="Thumb">
    Ses Basses a la Trapa, Mallorca, Spain
  </Thumb>
  <Thumb src="news/bestof2017/bestof_56.jpg" mdxType="Thumb">
    Las Pedreras, Almeria, Spain
  </Thumb>
  <Thumb src="news/bestof2017/bestof_57.jpg" mdxType="Thumb">
    La Techa, Alhaurin de la Torre, Spain
  </Thumb>
  <Thumb src="news/bestof2017/bestof_58.jpg" mdxType="Thumb">La Zubia, Granada, Spain</Thumb>
  <Thumb src="news/bestof2017/bestof_59.jpg" mdxType="Thumb">La Zubia, Granada, Spain</Thumb>
  <Thumb src="news/bestof2017/bestof_60.jpg" mdxType="Thumb">
    Greg Minaar's test track, La Zubia, Spain
  </Thumb>
  <Thumb src="news/bestof2017/bestof_7.jpg" mdxType="Thumb">Rasica, Ljubljana, Slovenia</Thumb>
  <Thumb src="news/bestof2017/bestof_10.jpg" mdxType="Thumb">
    Krnica Adventure, Bovec, Slovenia
  </Thumb>
  <Thumb src="news/bestof2017/bestof_15.jpg" mdxType="Thumb">
    Traversa Padon, Arabba, Italy
  </Thumb>
  <Thumb src="news/bestof2017/bestof_16.jpg" mdxType="Thumb">Double U, Canazei, Italy</Thumb>
  <Thumb src="news/bestof2017/bestof_17.jpg" mdxType="Thumb">
    Plose Flowtrail, Brixen, Italy
  </Thumb>
  <Thumb src="news/bestof2017/bestof_14.jpg" mdxType="Thumb">Burz, Arabba, Italy</Thumb>
  <Thumb src="news/bestof2017/bestof_4.jpg" mdxType="Thumb">
    Achtermannshöhe, Braunlage, Germany
  </Thumb>
  <Thumb src="news/bestof2017/bestof_3.jpg" mdxType="Thumb">Kaupanger, Norway</Thumb>
  <Thumb src="news/bestof2017/bestof_2.jpg" mdxType="Thumb">
    Alpirsbacher Schwarzwaldtrail, Sasbachwalden, Germany
  </Thumb>
  <Thumb src="news/bestof2017/bestof_5.jpg" mdxType="Thumb">
    King Uppu, Winterberg, Germany
  </Thumb>
  <Thumb src="news/bestof2017/bestof_1.jpg" mdxType="Thumb">
    Rock'n'Roll Queen, Massa Marittima, Italy
  </Thumb>
    </Gallery>
    <p>{`Check out our `}<a parentName="p" {...{
        "href": "https://www.instagram.com/trailguideapp"
      }}>{`Instagram account`}</a>{`
for more pictures and follow our adventures`}</p>
    <br />
    <h2>{`Trailguide Calender 2018`}</h2>
    <h4>{`Win 5 calendars`}</h4>
    <Image src="news/calendar1.jpg" mdxType="Image" />
    <p>{`We selected the very best pictures for our Trailguide Calender 2018.
Printed in high quality on premium matte paper in 45x30cm landscape.
You can now win five calenders.`}</p>
    <br />
    <Image src="news/calendar2.jpg" mdxType="Image" />
    <p>{`Go to our `}<a parentName="p" {...{
        "href": "https://www.facebook.com/trailguide.net"
      }}>{`Facebook page`}</a>{` share the
post and comment your dream MTB destination. Next Wednesday the 14.03.18
the winners are drawn. Good luck!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      